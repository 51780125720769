import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';

const companyLogos = [
  'almirall.png',
  'bayer.png',
  'boehringer.png',
  'catapult.png',
  'ceva.png',
  'chiesi.png',
  'gsk.png',
  'murata.png',
  'takeda.png',
  'ucb.png',
];

const carouselItems = companyLogos.map((logoName, i) => {
  return <img alt={`${logoName.split('.')[0]} company logo`} key={i} src={`/logos/${logoName}`} />;
});

export const CompaniesCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsInSlide, setItemsInSlide] = useState(5);

  const slideNext = () => {
    if (currentIndex > carouselItems.length) {
      setCurrentIndex(carouselItems.length);
    } else {
      setCurrentIndex(currentIndex + itemsInSlide);
    }
  };

  const handleOnSlideChanged = e => {
    setItemsInSlide(e.itemsInSlide);
    setCurrentIndex(e.item);
  };

  const slidePrev = () => {
    setCurrentIndex(currentIndex - itemsInSlide);
  };

  return (
    <div className="relative px-[28px] [&_.alice-carousel_img]:block [&_.alice-carousel_img]:h-15 [&_.alice-carousel_img]:mx-auto">
      {/* @ts-ignore */}
      <AliceCarousel
        responsive={{
          0: { items: 1 },
          500: { items: 2 },
          650: { items: 3 },
          900: { items: 5 },
        }}
        dotsDisabled={true}
        buttonsDisabled={true}
        items={carouselItems}
        slideToIndex={currentIndex}
        onSlideChanged={handleOnSlideChanged}
      />

      <button className="absolute top-4 left-0" onClick={slidePrev}>
        <ChevronLeftIcon className="w-7 h-7" />
      </button>

      <button className="absolute top-4 right-0" onClick={slideNext}>
        <ChevronRightIcon className="w-7 h-7" />
      </button>
    </div>
  );
};
