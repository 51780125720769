import clsx from 'clsx';
import Link from 'next/link';
import { MagnifyingGlassIcon, MagnifyingGlassPlusIcon } from '@heroicons/react/24/solid';
import { format, addWeeks, getTime, parseISO } from 'date-fns';
import { appImgPath } from '../../util/appImgPath';

type CampaignListProps = {
  campaigns: Array<{
    shortId: string;
    type: 'ico' | 'rfp';
    title: string;
    closingDate: string;
    details: {
      headerImage: {
        url: string;
      };
    };
  }>;
};

export const CampaignList: React.ElementType<CampaignListProps> = ({ campaigns }) => {
  return (
    <div className="grid justify-center grid-cols-[repeat(auto-fill,264px)] gap-8">
      {campaigns.map(discoverCall => {
        const discoverCallId = discoverCall.shortId;
        const closed = getTime(new Date()) > getTime(parseISO(discoverCall.closingDate));

        const colorFn = () => {
          const nowPlusWeek = addWeeks(new Date(), 1);
          const underWeek = getTime(parseISO(discoverCall.closingDate)) < getTime(nowPlusWeek);

          const color = underWeek
            ? closed
              ? 'text-content-informative'
              : 'text-content-negative'
            : 'text-content-positive';
          return color;
        };

        return (
          <div
            key={discoverCall.shortId}
            className="w-full overflow-hidden mx-auto bg-white rounded-xl border border-outline-neutral-light transition-all hover:shadow-dark-down"
          >
            <Link
              key={discoverCallId}
              href={`/discovercalls/${discoverCallId}`}
              className="block w-full h-full text-left no-underline"
            >
              <img
                src={appImgPath(discoverCall.details.headerImage.url, {
                  name: 'thumbnail',
                  params: {
                    width: 400,
                    quality: 70,
                  },
                })}
                className="w-full h-42 object-cover"
              />

              <div className="flex flex-col h-50 p-4">
                <h3 className="p-0 m-0 max-h-8 text-[16px] font-bold overflow-hidden uppercase leading-[1.3] mb-2.5 text-content-positive flex gap-x-2">
                  {discoverCall.type === 'rfp' ? (
                    <MagnifyingGlassPlusIcon className="w-5 h-5 text-content-positive" />
                  ) : (
                    <MagnifyingGlassIcon className="w-5 h-5 text-content-positive" />
                  )}
                  {discoverCall.type}
                </h3>

                <div className="line-clamp-5 overflow-hidden">
                  <h2 className="text-body-lg">{discoverCall.title}</h2>
                </div>

                <time
                  dateTime={format(parseISO(discoverCall?.closingDate), 'do MMMM yyyy')}
                  className={clsx('font-bold mt-auto text-body-md!', colorFn())}
                >
                  {closed ? 'Closed' : 'Closing date'}: {format(parseISO(discoverCall?.closingDate), 'do MMMM yyyy')}
                </time>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
