import clsx from 'clsx';
import Link from 'next/link';
import Head from 'next/head';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { CheckIcon } from '@heroicons/react/24/solid';
import { fetchCampaigns } from '@/hooks';
import { CampaignList, CompaniesCarousel } from '@/components/HomePage';
import { ENV } from '@/constants';
import { ButtonLink } from '@inpart-io/react-components/src';

const ACTIVE_CAMPAIGN_LIMIT = 30;
const CLOSED_CAMPAIGN_LIMIT = 5;

type HomePageProps = InferGetStaticPropsType<typeof getStaticProps>;

const bgBeforeStyles =
  'before:w-dvw before:right-[50%] before:left-[50%] before:-ml-[50vw] before:-mr-[50vw] before:top-0 before:absolute before:h-full before:z-[-1]';
const bgAfterStyles =
  'after:w-dvw after:right-[50%] after:left-[50%] after:-ml-[50vw] after:-mr-[50vw] after:top-0 after:absolute after:h-full after:z-[-1]';

const HomePage: React.ElementType<HomePageProps> = ({ activeCampaigns, previousCampaigns }) => {
  return (
    <div>
      <Head>
        <title>{ENV.NEXT_PUBLIC_ORGANISATION_NAME}</title>
      </Head>

      <section
        className={clsx(
          'w-full py-15 md:py-25 relative w-full before:bg-[url(/roads-large.jpg)] before:bg-cover before:bg-center after:-z-1 after:bg-linear-to-t after:from-general-neutral-dark/20 after:to-general-neutral-dark/80',
          bgBeforeStyles,
          bgAfterStyles,
        )}
      >
        <h1 className="text-white leading-10 text-[2rem] font-bold mb-3 max-w-100 md:mb-5.5">
          Engage with world leading R&amp;D teams
        </h1>
        <p className="text-white text-[18px] leading-6 mb-5 md:mb-7.5">
          View the latest campaigns seeking academic partners and funding new research
        </p>
        <ButtonLink
          href={`${ENV.NEXT_PUBLIC_COMPANIES_APP_URL}/register/academic?${new URLSearchParams({
            utm_source: 'discover-homepage',
            utm_medium: 'discover-public',
            utm_content: 'updates-button',
          }).toString()}`}
        >
          Register here for updates
        </ButtonLink>
      </section>

      <div className={clsx('pt-8 pb-10 mb-7.5 relative after:bg-general-neutral-light', bgAfterStyles)}>
        <ul className="p-0 m-0 list-none flex flex-col gap-y-4 md:gap-x-4 md:flex-row justify-between">
          <li className="flex items-start gap-x-2">
            <CheckIcon className="flex-[0_0_1.5rem] text-content-positive -left-4 text-[1.1rem]" />
            Receive the latest calls from industry seeking solutions and expertise from academia
          </li>

          <li className="flex items-start gap-x-2">
            <CheckIcon className="flex-[0_0_1.5rem] text-content-positive -left-4 text-[1.1rem]" />
            Get introductions to companies, collaborate on projects and commercialise technology
          </li>

          <li className="flex items-start gap-x-2">
            <CheckIcon className="flex-[0_0_1.5rem] text-content-positive -left-4 text-[1.1rem]" />
            Submit research projects, academic researchers, technologies, spinout companies, or centres of excellence
          </li>
        </ul>
      </div>

      <div className="mx-auto mb-10 w-full">
        <h3 className="my-[50px] font-bold text-[28px] text-center">Active campaigns</h3>

        {activeCampaigns.length === 0 ? (
          <p style={{ textAlign: 'center' }}>
            New campaigns coming soon.{' '}
            <Link href="/register/academic" style={{ fontWeight: 'bold' }}>
              Register
            </Link>{' '}
            to stay updated.
          </p>
        ) : (
          <CampaignList campaigns={activeCampaigns} />
        )}
      </div>

      {previousCampaigns.length > 0 && (
        <div className="mx-auto mb-10 w-full">
          <h3 className="my-[50px] font-bold text-[28px] text-center">Previous campaigns</h3>

          <CampaignList campaigns={previousCampaigns} />
        </div>
      )}

      <h3 className="my-[50px] font-bold text-[28px] text-center">Trusted by</h3>
      <CompaniesCarousel />
    </div>
  );
};

type CampaignList = Awaited<ReturnType<typeof fetchCampaigns>>['data']['data']['results'];

export const getStaticProps: GetStaticProps<{
  activeCampaigns: CampaignList;
  previousCampaigns: CampaignList;
}> = async () => {
  const currentDate = new Date();

  const [activeCampaigns, previousCampaigns] = await Promise.allSettled([
    fetchCampaigns({ closingAfter: currentDate, limit: ACTIVE_CAMPAIGN_LIMIT }),
    fetchCampaigns({ closingBefore: currentDate, limit: CLOSED_CAMPAIGN_LIMIT, order: 'desc' }),
  ]);

  return {
    props: {
      activeCampaigns: activeCampaigns.status === 'fulfilled' ? activeCampaigns.value.data.data.results : [],
      previousCampaigns: previousCampaigns.status === 'fulfilled' ? previousCampaigns.value.data.data.results : [],
    },
    revalidate: 60, // seconds
  };
};

export default HomePage;
